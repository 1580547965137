<template>
  <div class="events">
    <!-- Широкий спектр информации и практических инструментов, таких как информация о заболевании, рекомендации по лечению, образовательные программы и другое, созданы для того, чтобы помочь вам и вашей повседневной профессиональной деятельности -->
    <TopBlock
      :title="'Предстоящие мероприятия '"
      :description="'Актуальный календарь предстоящих мероприятий'"
      :images="[
        {
          src: require('@/assets/img/events_mob.jpg'),
          media: '(max-width: 767px)',
        },
        {
          src: require('@/assets/img/events_tablet.jpg'),
          media: '(max-width: 1220px)',
        },
        {
          src: require('@/assets/img/events.jpg'),
          media: '(max-width: 1919px)',
        },
        {
          src: require('@/assets/img/events_1920.jpg'),
          media: '(max-width: 2559px)',
        },
        {
          src: require('@/assets/img/events_2560.jpg'),
          media: '(max-width: 3839px)',
        },
        {
          src: require('@/assets/img/events_3840.jpg'),
          media: '(max-width: 999999999px)',
        },
      ]"
    />
    <Breadcrumbs
      :pages="[{ name: 'Календарь мероприятий &#34;АстраЗенека&#34;' }]"
    />
    <div class="filter">
      <div class="content">
        <div class="filter__row d-none d-md-grid" data-id="filterElem" v-if="!$root.isMobile">
          <Select
            :taggable="false"
            :push-tags="false"
            class="filter__item"
            v-model="therapeutic_areas"
            :items="therapeutic_areas_list"
            :placeholder="'Терапевтические области'"
            :multiple="true"
            @change="
              filterChange('Терапевтические области', therapeutic_areas_YM)
            "
          />
          <Select
            :taggable="false"
            :push-tags="false"
            class="filter__item"
            v-model="types"
            :items="types_list"
            :placeholder="'Тип мероприятия'"
            :multiple="true"
            @change="filterChange('Тип мероприятия', types_YM)"
          />
        </div>
        <div class="filter__opener d-md-none" v-if="$root.isMobile" data-id="filterElem" @click="openFilter()">
          <span class="mr-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 18.95V17.45H9.175V18.95H3ZM3 6.55V5.05H13.325V6.55H3ZM10.675 21V15.375H12.175V17.45H21V18.95H12.175V21H10.675ZM7.675 14.8V12.75H3V11.25H7.675V9.15H9.175V14.8H7.675ZM10.675 12.75V11.25H21V12.75H10.675ZM14.825 8.625V3H16.325V5.05H21V6.55H16.325V8.625H14.825Z"
                fill="#3C4242"
              />
            </svg>
          </span>
          Отфильтровать
        </div>
        <div class="filter-mobile d-md-none" v-if="filterOpened">
          <div
            @click="closeFilter()"
            class="filter-mobile__back button button_empty-pink button_small"
          >
            <span class="mr-2"
              ><svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 1L1 6L6 11"
                  stroke="currentColor"
                  stroke-linecap="square"
                ></path></svg
            ></span>
            Назад
          </div>
          <div class="filter-mobile__title">{{ filterTitle }}</div>
          <div class="filter-mobile__items" v-if="selectedFilter === null">
            <div
              class="filter-mobile__item"
              @click="selectFilter(0, 'Терапевтические области')"
            >
              Терапевтические области
              <div class="filter-mobile__item-icon">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="#3C4242"
                    stroke-linecap="square"
                  ></path>
                </svg>
              </div>
            </div>
            <div
              class="filter-mobile__item"
              @click="selectFilter(1, 'Тип мероприятия')"
            >
              Тип мероприятия
              <div class="filter-mobile__item-icon">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="#3C4242"
                    stroke-linecap="square"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="filter-mobile__filter" v-if="selectedFilter === 0">
            <Checkbox
              v-for="(check, ind) in therapeutic_areas_list"
              :key="ind"
              :label="check.label"
              :inputValue="check.value"
              v-model="therapeutic_areas"
              class="mb-6"
            />
            <div class="filter-mobile__buttons">
              <div
                class="filter-mobile__accept button button_pink mb-3"
                @click="
                  filterChange('Терапевтические области', therapeutic_areas_YM)
                "
              >
                Применить
              </div>
              <div
                class="filter-mobile__accept button button_empty-pink"
                @click="clearFilter('therapeutic_areas')"
              >
                Очистить
              </div>
            </div>
          </div>
          <div class="filter-mobile__filter" v-if="selectedFilter === 1">
            <Checkbox
              v-for="(check, ind) in types_list"
              :key="ind"
              :label="check.label"
              :inputValue="check.value"
              v-model="types"
              class="mb-6"
            />
            <div class="filter-mobile__buttons">
              <div
                class="filter-mobile__accept button button_pink mb-3"
                @click="filterChange('Тип мероприятия', types_YM)"
              >
                Применить
              </div>
              <div
                class="filter-mobile__accept button button_empty-pink"
                @click="clearFilter('type')"
              >
                Очистить
              </div>
            </div>
          </div>
        </div>
        <div
          class="filter__current-filter"
          v-if="currentFilter && currentFilter.length"
        >
          <div
            class="filter__current-filter-item"
            v-for="(item, ind) in currentFilter"
            :key="ind"
          >
            <span v-html="item.label"></span>
            <span
              @click="removeFilterItem(item.type, item.value)"
              class="ml-2 filter-item__remove"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2.00012C6.49 2.00012 2 6.49012 2 12.0001C2 17.5101 6.49 22.0001 12 22.0001C17.51 22.0001 22 17.5101 22 12.0001C22 6.49012 17.51 2.00012 12 2.00012ZM15.36 14.3001C15.65 14.5901 15.65 15.0701 15.36 15.3601C15.21 15.5101 15.02 15.5801 14.83 15.5801C14.64 15.5801 14.45 15.5101 14.3 15.3601L12 13.0601L9.7 15.3601C9.55 15.5101 9.36 15.5801 9.17 15.5801C8.98 15.5801 8.79 15.5101 8.64 15.3601C8.35 15.0701 8.35 14.5901 8.64 14.3001L10.94 12.0001L8.64 9.70012C8.35 9.41012 8.35 8.93012 8.64 8.64012C8.93 8.35012 9.41 8.35012 9.7 8.64012L12 10.9401L14.3 8.64012C14.59 8.35012 15.07 8.35012 15.36 8.64012C15.65 8.93012 15.65 9.41012 15.36 9.70012L13.06 12.0001L15.36 14.3001Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>
          <div
            class="filter__clear button button_empty-pink button_small"
            @click="clearFilter()"
          >
            Сбросить
            <span class="ml-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 3.50012L12.5 12.5001"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
                <path
                  d="M12.5 3.50012L3.5 12.5001"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="events__row">
        <div class="events__list" v-if="FilteredEvents && FilteredEvents.length" ref="eventsList">
          <Event
            v-for="(event, ind) in FilteredEvents"
            :key="ind"
            :event="event"
            class="mb-6"
            large
          />
        </div>
        <div class="events__list" v-else ref="eventsList">
          <div class="events__empty">Нет ближайших мероприятий</div>
        </div>
        <EventsCalendar
          :style="`position: sticky; top: ${user.verified ? '107px' : '140px'}`"
          :showFilter="false"
          @dayClick="toEvents()"
          @goPrev="goPrev"
          @goNext="goNext"
          @updFilter="updFilter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TopBlock from "../../components/pageComponents/TopBlock.vue";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import Select from "../../components/form-elements/Select.vue";
import Checkbox from "../../components/form-elements/Checkbox.vue";
import Event from "../../components/main/events/Event.vue";
import EventsCalendar from "../../components/main/events/calendar/EventsCalendar.vue";
import { mapActions, mapGetters } from "vuex";
import filter from "@/mixins/filter";
import { bus } from "@/main";
export default {
  metaInfo: {
    title: 'Календарь мероприятий "АстраЗенека"',
  },
  name: "Events",
  mixins: [filter],
  components: {
    TopBlock,
    Breadcrumbs,
    Select,
    Checkbox,
    Event,
    EventsCalendar,
  },
  data: () => ({
    filterTitle: "Фильтр",
    filterOpened: false,
    items: [],
  }),
  computed: {
    ...mapGetters([
      "Events",
      "FilteredEvents",
      "NosologysFilter",
      "TypesFilter",
      "user",
    ]),
    therapeutic_areas_list() {
      return this.NosologysFilter.map((el) => ({
        label: el.title,
        value: el.slug || el.id,
      }));
    },
    types_list() {
      return this.TypesFilter.map((el) => ({
        label: el.name,
        value: el.code || el.id,
      }));
    },
    therapeutic_areas_YM() {
      let arr = [];
      this.therapeutic_areas.forEach((i) => {
        let find = this.therapeutic_areas_list.find((el) => el.value === i);
        if (find) {
          arr.push(find.label);
        }
      });
      return arr;
    },
    types_YM() {
      let arr = [];
      this.types.forEach((i) => {
        let find = this.types_list.find((el) => el.value === i);
        if (find) {
          arr.push(find.label);
        }
      });
      return arr;
    },
  },
  methods: {
    ...mapActions(["fetchEvents", "fetchNosologysFilter", "fetchTypesFilter"]),
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
     async updFilter(filter) {
      await this.fetchEvents(filter);
    },
    toEvents() {
      if (window.innerWidth < 768) {
        this.$refs.eventsList.scrollIntoView({ behavior: "smooth" });
      }
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "events page calendar date click", {
          "events page calendar date click": {
             ...this.$root.ymFields
          },
        });
      }
    },
    goPrev(month) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "events page calendar month fllter use", {
          "events page calendar month fllter use": {
            month: month,
             ...this.$root.ymFields
          },
        });
      }
    },
    goNext(month) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "events page calendar month fllter use", {
          "events page calendar month fllter use": {
            month: month,
             ...this.$root.ymFields
          },
        });
      }
    },
    filterChange(name, value) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "events page filter use", {
          "events page filter use": {
            "used filter": `${name} : ${value.join(",")}`,
             ...this.$root.ymFields
          },
        });
      }
      this.closeFilter();
    },
  },
  async mounted() {
       if (
      this.user &&
      Object.keys(this.user).length &&
      typeof this.user === "object" &&
      ((this.user.flags && !this.user.flags.includes("filter")) ||
        !this.user.flags)
    ) {
        // bus.$emit("startFilterHelp");
    }
    await this.fetchTypesFilter();
  },
  watch: {
    therapeutic_areas(o, n) {
      this.buildQuery();
    },
    types() {
      this.buildQuery();
    },
  },
};
</script>

<style lang="scss" scoped>
.events {
  &__empty {
    position: relative;
    z-index: 2;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #1F1F1F;
  }
  &__row {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 343px;
    column-gap: 52px;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 16px;
    }
  }

  &__list {
    @media screen and (max-width: 1220px) {
      grid-row: 2;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }
}
.filter {
  margin-top: 64px;
  @media screen and (max-width: 1220px) {
    margin-top: 40px;
  }
  &__row {
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
    }
  }

  &__item {
    max-width: none;
  }

  &__current-filter-item {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-right: 18px;

    & svg {
      color: #d9dada;
      transition: 0.3s;
    }

    &:hover {
      & svg {
        color: #830051;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__current-filter {
    margin-top: 18px;
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  &__opener {
    padding: 0 19px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;
    background: #f8f8f8;
    border: 1px solid #d8dada;
    border-radius: 999px;
  }

  &__clear {
    margin-bottom: 14px;
  }

  &-mobile {
    padding: 32px 16px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;

   &__buttons {
      position: absolute;
      bottom: 60px;
      width: 100%;
      left: 0;
      padding: 0 16px;
    }

    &__filter {
      max-height: calc(100vh - 310px);
      overflow-y: auto;
    }

    &__back {
      margin-bottom: 36px;
      width: 80px;
      height: 28px;
    }

    &__title {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__item {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #3c4242;
    }
  }
}
.filter-item__remove {
  cursor: pointer;
}
</style>

<style>
.selectaria__select {
  max-width: none;
}
</style>